import * as React from "react";
// @ts-ignore
import classes from "./SideDrawer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import SideDrawerItem from "./components/SideDrawerItem";
import { sideDrawerItems } from "./SideDrawer.items";
import ContactBox from "./contactBox";
import { ScrollUtil } from "../../utils/scroll.util";
import { useLocation } from "@reach/router";

const SideDrawer = (props: any) => {

  const { drawerOpen, toggleOpenDrawerHandler } = props;
  const location = useLocation();

  let sideDrawerClasses = [classes.sidebar];

  if (drawerOpen) {
    sideDrawerClasses = [classes.sidebar, classes.open];
  }
  
  React.useEffect(() => {
    if (
      location.state &&
      location.state.sectionToScroll &&
      location.state.delay
    ) {
      ScrollUtil.scrollTo(location.state.sectionToScroll, location.state.delay);
      delete location.state.sectionToScroll;
      delete location.state.delay;
    }
  });

  return (
    <div className={drawerOpen ? classes.modalOpen : " "} onClick={toggleOpenDrawerHandler}>
      <div
        className={sideDrawerClasses.join(" ")}
      >

        <div className={classes.iconContainer} onClick={toggleOpenDrawerHandler}>
          <FontAwesomeIcon icon={faTimes} size="2x" className="icon" />
        </div>

        {sideDrawerItems.map((sideDrawerItem) => (
          <SideDrawerItem key={sideDrawerItem.label} {...sideDrawerItem} />
        ))}
        <div className={classes.contactBox}>
          <ContactBox vertical />
        </div>
      </div>
    </div>
  );
};

export default SideDrawer;
