import { graphql, StaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import React from "react";
import GenericImage from "../shared/image/Image";
// @ts-ignore
import classes from "./CertModal.module.css";

const CertModal = (props: any) => (
  <StaticQuery
    query={graphql`
      query {
        paper: file(relativePath: { eq: "Paper.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData
      const { show, close, modalData } = props;
      const { alt, icon, paperInfo, sectionName } = modalData;
      if (!show) {
        return <></>;
      }
      const imageData = data.paper.childImageSharp.fluid;
      return (
        <div className={classes.modalRoot} onClick={() => close()}>
          <BackgroundImage
            fluid={imageData}
            style={{
              backgroundSize: "cover",
              zIndex: 10
            }}>
            <div className={classes.paper}>
              <div className={classes.paperContent}>
                <button className={classes.closeButton} onClick={() => close()}>
                  X
                </button>
                <div className={classes.modalHeader}>
                  <div className={classes.imageContainer}>
                    <GenericImage
                      alt={alt}
                      filename={icon}
                      classes={classes.image}
                    />
                  </div>
                  <div className={classes.sectionNameContainer}>
                  <p className={classes.sectionName}>{sectionName}</p>
                  </div>
                </div>
                <p className={classes.text}>{paperInfo}</p>
              </div>
            </div>
          </BackgroundImage>
        </div>
      );
    }}
  />
);

export default CertModal;
