import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import * as React from "react";
// @ts-ignore
import classes from "./Image.module.css";

const GenericImage = (props: any) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 1920, quality:100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    `}
    render={(data: any) => {
      const image = data.images.edges.find((n: any) => {
        return n.node.relativePath.includes(props.filename);
      });
      if (!image) {
        return null;
      }
      return <Img
        className={props.classes || classes.small}
        alt={props.alt}
        fluid={image.node.childImageSharp.fluid}
      />;
    }}
  />
);

export default GenericImage
