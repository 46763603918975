/* tslint:disable */
import * as React from "react";
import { Link } from "gatsby";
import { useState } from "react";
import "../css/styles.css";
import Footer from "./Footer/Footer";
import HeaderMenu from "./HeaderMenu/HeaderMenu";
import SideDrawer from "./Sidedrawer/SideDrawer";
import CertModal from "./CertModal/CertModal";

// @ts-ignore
import classes from "./Layout.module.css";

export interface LayoutProps {
  children: any;
}

interface IModalData {
  icon: string;
  paperInfo: string;
  alt: string;
  sectionName: string;
}

const Layout = (props: LayoutProps) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showBottomBtn, setVisibleBottomBtn] = useState(true);

  const [modalData, setModalData] = useState({
    icon: "",
    paperInfo: undefined,
    alt: "",
    sectionName: "",
  });

  React.useEffect(() => {
    document.addEventListener('scroll', trackScrolling);
    return () => {
      document.removeEventListener('scroll', trackScrolling);
    }
  }, []);

  const trackScrolling = () => {
    const element = document.getElementById('scroll-component');
    if (isBottom(element)) {
      setVisibleBottomBtn(false);
    } else {
      setVisibleBottomBtn(true);
    }
  };
  const isBottom = (el: any) => {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  const handleClick = (props: IModalData) => {
    if (props) {
      setModalData({
        icon: props.icon,
        paperInfo: props.paperInfo,
        alt: props.alt,
        sectionName: props.sectionName,
      });
    }
    setShowModal(!showModal);
  };

  const toggleOpenDrawerHandler = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <React.Fragment>
      <HeaderMenu
        drawerOpen={drawerOpen}
        toggleOpenDrawerHandler={toggleOpenDrawerHandler}
        showModal={showModal}
        setShowModal={setShowModal}
        setModalData={setModalData}
      />
      <SideDrawer
        drawerOpen={drawerOpen}
        toggleOpenDrawerHandler={toggleOpenDrawerHandler}
      />
      {/* <CertModal /> */}
      <div id="scroll-component">
        {props.children}
      </div>
      <Footer />
      {showBottomBtn && <Link to="#contact" className={classes.linkPurchase}>
        <button className={classes.purchaseButton}>
          Request Sample
        </button>
      </Link>}
      <CertModal
        show={showModal}
        close={handleClick}
        modalData={modalData}
      />
    </React.Fragment>
  );
};

export default Layout;

export const withLayout = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) =>
  class WithLayout extends React.Component<P & LayoutProps> {
    // isBottom(el) {
    //   return el.getBoundingClientRect().bottom <= window.innerHeight;
    // }
    
    // componentDidMount() {
    //   document.addEventListener('scroll', this.trackScrolling);
    // }
    
    // componentWillUnmount() {
    //   document.removeEventListener('scroll', this.trackScrolling);
    // }
    // trackScrolling = () => {
    //   const element = document.getElementById('scroll-component');
    //   if (this.isBottom(element)) {
    //     console.log('header bottom reached');
    //   }
    // };
    render() {
      return (
        <Layout>
          <div id="scroll-component">
            <WrappedComponent {...this.props} />
          </div>
        </Layout>
      );
    }
  };
