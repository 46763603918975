import * as React from "react";
// @ts-ignore
import classes from "./Footer.module.css";
import VirgilLogo from "./components/VirgilLogo/VirgilLogo";
import FooterInfo from "./components/FooterInfo/FooterInfo";

export const Footer = () => {
  return (
    <div className={classes.footerWrapper}>
      <VirgilLogo />
      <FooterInfo />
    </div>
  );
};

export default Footer;
