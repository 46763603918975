import * as React from "react";
import Image from "../../../shared/image/Image";
// @ts-ignore
import classes from "./VirgilLogo.module.css";

export const VirgilLogo = () => {

  const openWebSite = () => {
    window.open("https://vgfinc.com", "_blank");
  };

  return (
    <div className={classes.vhfLogoContainer} onClick={openWebSite}>
      <Image
        alt="VHF Logo"
        classes={classes.vhfLogo}
        filename="vgf-logo.png"
      />
    </div>
  );
};

export default VirgilLogo;
