/* tslint:disable */
import { Link } from "gatsby";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useEffect, useState } from "react";
// @ts-ignore
import logo from "../../images/header/header-logo.png";
import { amarilloBrightIcons } from "../AmarilloBright/AmarilloBrightSectionInfo";
import CertModal from "../CertModal/CertModal";
import GenericImage from "../shared/image/Image";
// @ts-ignore
import classes from "./HeaderMenu.module.css";

interface IModalData {
  icon: string;
  paperInfo: string;
  alt: string;
  sectionName: string;
}

export const HeaderMenu = (props: any) => {
  const [headerClass, setHeaderClass] = useState("secondaryHeader");

  const handleClick = (_props: IModalData) => {
    if (_props) {
      props.setModalData({
        icon: _props.icon,
        paperInfo: _props.paperInfo,
        alt: _props.alt,
        sectionName: _props.sectionName,
      });
    }
    props.setShowModal(!props.showModal);
  };

  function listenScrollEvent() {
    if (window.scrollY > 100) {
      setHeaderClass("ScrolledSecondaryHeader");
    } else {
      setHeaderClass("secondaryHeader");
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", () => listenScrollEvent());
  });

  return (
    <div className={classes.header}>
      <div className={classes[headerClass]}>
        <div className={classes.hamburger}>
          <FontAwesomeIcon icon={faBars} size="2x" onClick={props.toggleOpenDrawerHandler} className={classes.hamburgerIcon} />
          {amarilloBrightIcons.map(
            ({ alt, icon, paperInfo, sectionName, href }, index) => (
              <div
                key={index}
                className={classes.iconContainer}
                onClick={href ? null : () =>
                  handleClick({ icon, paperInfo, alt, sectionName })
                }>
                <a href={href} target={href ? "_blank" : null} rel={href ? "noopener noreferrer" : null}>
                <GenericImage
                  alt={alt}
                  filename={icon}
                  classes={classes.icon}
                />
                </a>
              </div>
            ),
          )}
        </div>
        <a className={classes.amarillo} href={'/'} >
          <img src={logo} className={classes.amarilloLogo} alt="header logo" />
        </a>

        <div className={classes.purchaseBtn}>
          <Link to="#contact" className={classes.linkPurchase}>
            <button className={classes.purchaseButton}>
              Request Sample
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeaderMenu;
