import * as React from "react";
// @ts-ignore
import classes from "./FooterInfo.module.css";
import GenericImage from "../../../shared/image/Image";
import {instagramLink} from "./Utils";

export const FooterInfo = () => {
  return (
    <div className={classes.footerInfoContainer}>
      <p className={classes.P} style={{ fontFamily: "Barrio" }}>
        General questions?
        <a
          className={classes.A}
          href="mailto:info@vgfinc.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textAlign: "center" }}
        >
          {" "}
          info@vgfinc.com
        </a>
      </p>
      <p className={classes.P}>
        Phone:
        <a
          className={classes.A}
          href="tel:509-865-6422"
          target="_blank"
          rel="noopener noreferrer"
        >
          {" "}
          509-865-6422
        </a>
      </p>
      <p className={classes.pImage}>
        <a
          href={instagramLink}
          target="_blank"
          rel={instagramLink}
        >
          <GenericImage
            alt="Instagram"
            filename="instagram.png"
          />
        </a>
      </p>
    </div>
  );
};

export default FooterInfo;
