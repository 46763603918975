/* tslint:disable */

export interface IAmarilloBrightSection {
  icon: string;
  alt?: string;
  paperInfo?: string;
  sectionName?: string;
  href?: string;
}

export const amarilloBrightIcons: IAmarilloBrightSection[] = [
  {
    icon: "fdaIcon.png",
    alt: "FDA Certification",
    sectionName: "The FDA Food Safety Modernization Act (FSMA)",
    paperInfo:
      "Virgil Gamache Farms, Inc. is FSMA Certified. VGF is one of a handful in the industry that is fully FSMA " +
        "compliant. FSMA certification means we meet the revised, tougher best-farming and manufacturing standards " +
        "Congress created in 2011. These practices enhance hop quality, product safety, and allow for complete " +
        "traceability of products. Traceability is vitally important. In case of an industry safety event or a " +
        "product recall, traceability provides vital information regarding when, where and how the products were produced.",
  },
  {
    icon: "globalapIcon.png",
    alt: "Global G.A.P",
    sectionName: "Global G.A.P. Certification",
    paperInfo:
      "In addition to FSMA certification, Virgil Gamache has obtained Global G.A.P. certification through our implementation of sustainable farming programs that are impacting agriculture worldwide. Specifically for hop growers, the program offers standards covering propagation material, soil management, pre-harvest evaluation, harvest and post-harvest activities. What’s more, participating farmers take steps to improve the safety, health and welfare of their workers, as well as to conserve natural resources. The Global G.A.P program at VGF is also one more level of product safety protection for our brewers.  Learn more about Global G.A.P. here.",
  },
  {
    icon: "kIcon.png",
    alt: "Kosher",
    sectionName: "Kosher",
    paperInfo:
      `Keeping with our philosophy of harmonizing our certification programs, we were honored to be accepted into the world’s oldest food quality assurance program.`
  },
  {
    icon: "salmonSafeIcon.png",
    alt: "Salmon Safe",
    sectionName: "Salmon Safe",
    paperInfo:
      "Finally, protection of our rivers, streams and ground water is a top priority for the Gamache family. For that reason, VGF farms works diligently to meet all the Salmon-Safe Certification requirements. This means that VGF is:\n" +
      "\n" +
      "Controlling erosion by planting soil-protecting cover crops\n" +
      " \n" +
      "Protecting the watershed to assure healthy salmon, rivers and streams\n" +
      " \n" +
      "Implementing Integrated Pest Management utilizing organic, natural pest control\n" +
      " \n" +
      "Using high-efficiency drip irrigation\n" +
      " \n" +
      "Preventing harmful chemicals from entering streams and aquifers\n" +
      " \n" +
      "Promoting plant and wildlife diversity in the region\n" +
      "You can learn more about Virgil Gamache farms family legacy in the Yakima Valley here.",
  },
  {
    icon: "AmarilloCertIcons_HopTechnic.png",
    alt: "HopTech",
    href: "http://www.hoptechnic.com"
  },
  {
    icon: "truePellet.png",
    alt: "TruePellet",
    href: "https://hoptechnic.com/truepellet/",
  },
];
