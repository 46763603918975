import * as React from "react";
// @ts-ignore
import classes from "./SideDrawerItem.module.css";
import { ISideDrawerItem } from "../SideDrawer.items";
import { navigate } from "gatsby";
import { useLocation } from "@reach/router";
import { ScrollUtil } from "../../../utils/scroll.util";

const SideDrawerItem = ({ label, path, href }: ISideDrawerItem) => {
  const location = useLocation();

  const handleItemClick = () => {

    const [pageUrl, elemId] = path.split("#");
    const isSamePage = pageUrl === location.pathname;
    const delay = isSamePage ? 0 : 500;

    if (!isSamePage) {
      navigate(pageUrl, {
        state: { sectionToScroll: elemId, delay },
      });
    } else {
      ScrollUtil.scrollTo(elemId, delay);
    }
  };

  const handleWebSite = () => {
    if (label === "SPYDER CHART") {
      window.open("https://hoptechnic.com/blog/post/hoptechnic-sensory-and-spider-charts-galore", "_blank");
    } else {
      window.open(href, "_blank");
    }
  };

  return (
      href ?
          <div className={classes.item}>
            <a className={classes.itemLabel} onClick={handleWebSite} target="_blank" rel="noopener nofollow">{label}</a>
          </div> :
          <div
            className={classes.item}
            onClick={handleItemClick}
          >
            <p className={classes.itemLabel}>{label}</p>
          </div>
  );
};

export default SideDrawerItem;
