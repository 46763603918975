import React, { FC } from "react"
import { useStaticQuery, graphql } from "gatsby"

interface IGetIcon {
  phoneIcon: string
  emailIcon: string
}

const useGetContactBoxIcon = (): IGetIcon => {
  const { phoneIcon, emailIcon } = useStaticQuery(graphql`
    query {
      
      emailIcon: file(relativePath: { eq: "mail.svg" }) {
        publicURL
      }

      phoneIcon: file(relativePath: { eq: "phone.svg" }) {
        publicURL
      }
    }
  `)
  

  return { phoneIcon:phoneIcon.publicURL, emailIcon:emailIcon.publicURL }
}
export default useGetContactBoxIcon
