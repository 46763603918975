import smoothscroll from "smoothscroll-polyfill";

if (typeof window !== `undefined`) {
  smoothscroll.polyfill();
}

export class ScrollUtil {
  static scrollTo(
    elementId: string,
    delay = 0,
    behavior: ScrollBehavior = "smooth"
  ) {
    if (typeof window === "undefined") {
      console.error("no window");
      return;
    }

    window.requestAnimationFrame(() => {
      const element = document.getElementById(elementId);
      if (!element) {
        console.error(`Element of Id (${elementId}) not found`);
        return;
      }

      let offset = element.offsetTop;

      try {
        let bodyRect = document.body.getBoundingClientRect();
        let bodyStyle = window.getComputedStyle(document.body, null);

        // need to handle the padding for the top of the body
        let paddingTop = parseFloat(bodyStyle.getPropertyValue("padding-top"));

        let elementRect = element.getBoundingClientRect();
        offset = elementRect.top - paddingTop - bodyRect.top - 60;
      } catch (err) {
        console.error(err);
      }

      setTimeout(() => {
        window.scrollTo({ top: offset, left: 0, behavior });
      }, delay);
    });
  }
}
