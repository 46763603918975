import * as React from "react";

export interface ISideDrawerItem {
  label: string;
  path: string;
  href?: string;
}

export const sideDrawerItems: ISideDrawerItem[] = [
  {
    label: "PURCHASE",
    path: "/hops-for-sale",
  },
  {
    label: "AMARILLO®",
    path: "/#landing",
  },
  {
    label: "VIDEO",
    path: "/#story-of-amarillo-video",
  },
  {
    label: "FLAVOR SPECTRUM",
    path: "/#flavor-spectrums",
  },
  // {
  //   label: "FLAVOR PROFILES",
  //   path: "/#flavor-profiles",
  // },
  // {
  //   label: "FUN FACTS",
  //   path: "/#funish-facts",
  // },
  {
    label: "CONTACT",
    path: "/#contact",
  },
  {
    label: "SPYDER CHART",
    path: "/spyder-chart",
    href: "https://hoptechnic.com/app",
  },
  {
    label: "BRANDING",
    path: "/brand",
  },
  {
    label: "NEWS & MEDIA",
    path: "/news-media",
  },
];
